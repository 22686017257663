<template>
<div>
    <div v-for="(menu,index) in allMenu" :key="index" class="menu mt-2" @click="menuSelected(menu.menuType,index)">
        <a-divider :id="'divider'+index" type="vertical" class="divider" />
        <img class="mr-2" :src="require(`@/assets/${menu.icon}`)" width="18px" />
        <span :id="'menuName'+index">{{menu.menuName}}</span>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            allMenu:[
                {menuName:'DASHBOARD', icon: 'home.png', menuType: 'dashboard'},
                {menuName:'จัดการนิยาย', icon: 'modules.png', menuType: 'manageNovel'},
                {menuName:'ตรวจสอบยอดขาย', icon: 'growth.png', menuType: 'saleCheck'},
                {menuName:'กรอกประวัติ/ถอนเงิน', icon: 'growth.png', menuType: 'history'},
                {menuName:'ภาษีหัก ณ ที่จ่าย', icon: 'tax.png', menuType: 'tax'},
            ]
        }
    },
    mounted() {
        if(location.pathname.substring(location.pathname.lastIndexOf("/")+1) != "Control"){
            this.menu = location.pathname.substring(location.pathname.lastIndexOf("/")+1)
            let index = this.allMenu.indexOf(this.allMenu.find(x => x.menuType === this.menu));
            //console.log('index ,',index)
            this.menuSelected(this.menu, index)
        }else{
            this.menuSelected('dashboard', 0)
        }
    },
    methods: {
        menuSelected(menuType,index){
            this.$emit('menuSelected', menuType)
            this.menuActive(index)
        },
        menuActive(index) {
            var iconDefault = ['home','modules','growth','growth','tax']
            for (var n = 0; n < 5; n++) {
                document.getElementById('menuName' + n).classList.remove("menuActive")
                document.getElementById('divider' + n).classList.remove("dividerActive")
                this.allMenu[n].icon = iconDefault[n]+".png"
            }
            document.getElementById('menuName' + index).classList.add("menuActive")
            document.getElementById('divider' + index).classList.add("dividerActive")
            this.allMenu[index].icon = iconDefault[index]+"1.png"
        }
    }

};
</script>

<style scoped>
.menuActive{
    color: #040246;
    font-weight: bold;
}

.menu:hover{
    cursor: pointer;
}

.divider {
    width: 5px;
    height: 3rem;
    margin-left: 0px;
}

.dividerActive {
    background: #040246;
}
</style>
