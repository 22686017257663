<template>
<div class="scope">
    <b-col>
        <label for="novelName">ชื่อนิยาย</label>
        <b-form-input id="novelName" v-model="novelName" placeholder="ชื่อนิยาย"></b-form-input>
    </b-col>
    <b-row>
        <b-col>
            <label for="category">หมวดหมู่</label>
            <b-form-select v-model="category" :options="category"></b-form-select>
        </b-col>
        <b-col>
            <label for="type">ประเภทนิยาย</label>
            <b-form-radio-group v-model="selected" :options="types" class="mb-3" value-field="item" text-field="name" disabled-field="notEnabled"></b-form-radio-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <label for="type">ปกหนังสือ</label>
            <b-form-file class="file" v-model="fileBookHover" placeholder="อัพโหลดภาพปกหนังสือ" browse-text="เลือกรูป"></b-form-file>
        </b-col>
        <b-col>
            <label for="type">ภาพพื้นหลัง</label>
            <b-form-file class="file" v-model="fileBg" placeholder="อัพโหลดภาพพื้นหลัง" browse-text="เลือกรูป"></b-form-file>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col>
            <label for="desc">คำอธิบาย</label>
            <ckeditor id="desc" :editor="editor" v-model="editorData"></ckeditor>
        </b-col>
    </b-row>
</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            novelName: "",
            category: [],
            selected: 'A',
            types: [{
                    item: 'A',
                    name: 'นิยายแต่ง'
                },
                {
                    item: 'B',
                    name: 'นิยายแปล'
                }
            ],
            fileBookHover: [],
            fileBg: [],
            editor: ClassicEditor,
            editorData: '',
        }
    }
}
</script>

<style scoped>
@import 'custom.css';

.scope {
    padding: 2%;
    height: 100%;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
}

label {
    font-size: 12px;
    margin-bottom: 0px;
}

input,
select {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 8px;
    background-color: transparent;
    margin-bottom: 3%;
}

input:focus,
select:focus {
    border-color: #A8A8A8;
    box-shadow: none;
}

/* .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

:root {
    --ck-border-radius: 10px;
}
.ck-toolbar_grouping{
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
} */

/* :root {
    --color: red;
}

.ck-editor {
    color: var(--color);
} */

/* .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #040246 !important;
    background-color: #040246 !important;
}

.custom-file-label{
    background-color: transparent !important;
    border: 1px solid #A8A8A8 !important;
    border-radius: 8px !important;
}

.file label{
    background-color: transparent !important;
    border: 1px solid #A8A8A8 !important;
    border-radius: 8px !important;
} */
</style>
