export const domainconfig = {
    state: {
        admin_domain: "https://admin.novelrealm.com",
        writer_domain: "https://writer.novelrealm.com",
        publisher_domain: "https://publisher.novelrealm.com"
    },
    getters: {
        GET_ADMIN_DOMAIN(state) {
            return state.admin_domain
        },
        GET_WRITER_DOMAIN(state) {
            return state.writer_domain
        },
        GET_PUBLISHER_DOMAIN(state) {
            return state.publisher_domain
        }
    }
}