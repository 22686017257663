export const modeStore = {
    state: {
        mode: localStorage.getItem('mode')
    },
    actions: {
        SET_MODE({ commit }, mode) {
            commit("SET_MODE", mode)
            localStorage.setItem('mode', mode)
        },
    },
    mutations: {
        SET_MODE: (state, mode) => {
            state.mode = mode
        }
    },
    getters: {
        GET_MODE(state) {
            return state.mode
        }
    }
}