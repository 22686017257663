import Vue from 'vue'
import Router from 'vue-router'
const  Homepage = () => import('@/components/pages/Homepage')
const Payment = () => import('@/components/pages/Payment')
const AllNovelList = () => import('@/components/pages/AllNovelList')
const Profile = () => import('@/components/pages/Profile')
const ProfileNoFb = () => import('@/components/pages/Profile_no_FB')
const Writer = () => import('@/components/pages/Writer')
const PaymentGuide = () => import('@/components/pages/PaymentGuide')
const ContactUs = () => import('@/components/pages/ContactUs')
const Novel = () => import('@/components/pages/Novel')
const NovelPack = () => import('@/components/pages/Novel_pack')
const Chapter = () => import('@/components/pages/Chapter')
const SeeNovel = () => import('@/components/pages/SeeNovel')
const Search = () => import('@/components/pages/Search')
const Guide = () => import('@/components/pages/Guide')
const Objective = () => import('@/components/pages/Objective')
const Policy = () => import('@/components/pages/Policy')
const ControlPanel = () => import('@/components/pages/ControlPanel')
const Dashboard = () => import('@/components/elements/control/Dashboard')
const ManageNovel = () => import('@/components/elements/control/ManageNovel')
const SaleCheck = () => import('@/components/elements/control/SaleCheck')
const History = () => import('@/components/elements/control/History')
const Tax = () => import('@/components/elements/control/Tax')
const NotFound = () => import('@/components/pages/404')
const SetPass = () => import('@/components/pages/SetPass')
const User = () => import('@/components/pages/User')
const ShopeeConfirm = () => import('@/components/pages/Shopee_confirm')
const Notification = () => import('@/components/pages/Notification')
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Homepage
    },
    {
      path: '/payment',
      component: Payment
    },
    {
      path: '/allNovel',
      component: AllNovelList
    },
    {
      path: '/profile',
      component: Profile
    },
    {
      path: '/profile_no_fb',
      component: ProfileNoFb
    },
    {
      path: '/writer',
      component: Writer
    },
    {
      path: '/paymentGuide',
      component: PaymentGuide
    },
    {
      path: '/contact',
      component: ContactUs
    },
    {
      path: '/novel/:novel_id',
      component: Novel
    },
    {
      path: '/novel/:novel_id/pack',
      component: NovelPack
    },
    {
      path: '/novel/:novel_id/chapter/:chapter_id',
      component: Chapter
    },
    {
      path: '/latestNovel/:type',
      component: SeeNovel
    },
    {
      path: '/search',
      component: Search
    },
    {
      path: '/guide',
      component: Guide
    },
    {
      path: '/objective',
      component: Objective
    },
    {
      path: '/policy/:page',
      component: Policy
    },
    //ทำ page title ถึงตรงนี้
    {
      path: '/Control',
      component: ControlPanel
    },
    {
      path: '/Control/Dashboard',
      component: Dashboard
    },
    {
      path: '/Control/ManageNovel',
      component: ManageNovel
    },
    {
      path: '/Control/SaleCheck',
      component: SaleCheck
    },
    {
      path: '/Control/History',
      component: History
    },
    {
      path: '/Control/Tax',
      component: Tax
    },
    {
      path: '/404',
      component: NotFound
    },
    {
      path: '/login/*',
      component: Homepage
    },
    {
      path: '/reset/:token',
      component: SetPass
    },
    {
      path: '/user/:id',
      component: User
    },
    //Backport URL
    {
      path: '/detail/:novel_id',
      component: Novel
    },
    {
      path: '/detail/:novel_id/chapter/:chapter_id',
      component: Chapter
    },
    {
      path: '/read/:chapter_id',
      component: Chapter
    },
    //Shopee Payment
    {
      path: '/shopee/:reference_id',
      component: ShopeeConfirm
    },
    //Notification
    {
      path: '/notification',
      component: Notification
    }
  ]
})

