<template>

    <a-alert
        v-if="!isAcceptCookie"
        class="cookie"
        message="เว็บไซต์นี้ใช้คุกกี้เพื่อนำข้อมูลสถิติของลูกค้าทุกท่านมาปรับปรุงการพัฒนาให้การใช้งานเว็บไซต์เป็นไปอย่างสะดวกมากยิ่งขึ้น
        หากท่านใช้บริการเว็บไซต์นี้ต่อไปโดยไม่มีการปรับตั้งค่าใดๆ นั่นเป็นการแสดงว่าท่านอนุญาตยินยอมที่จะรับคุกกี้บนเว็บไซต์และนโยบายสิทธิส่วนบุคคลของเรา"
        type="info"
        close-text="ยอมรับ"
        @close="acceptCookie" />
</template>

<script>
import Cookie from 'js-cookie'
export default {
    computed: {
        isAcceptCookie: function () {
            return Cookie.get('acceptcookie') === "true"
        }
    },
    methods: {
        acceptCookie: function() {
            Cookie.set('acceptcookie', "true", { expires: 3650 })
        }
    }
}
</script>

<style scoped>
.cookie{
    width: 100vw;
    position: sticky;
    bottom: 0;
}
.cookie{
    padding-right:100px;
}
.cookie :deep(.ant-alert-close-text){
    font-size: 15px;
}
.cookie :deep(.ant-alert-close-icon){
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
}
</style>