// import axios from "axios";

export const paymentStore = {

    state: {
        cash: 0,
        paymentChannel: "",
        isSelected: false,
        modal: false
    },
    mutations: {
        SET_CASH: (state, cash) => {
            state.cash = cash
        },
        SET_PAYMENT_CHANNEL: (state, paymentChannel) => {
            state.paymentChannel = paymentChannel
        },
        SET_IS_SELECTED: (state, isSelected) => {
            state.isSelected = isSelected
        },
        SET_SHOW_MODAL: (state, modal) => {
            state.modal = modal
        }
    },
    actions: {
        SET_CASH: ({ commit }, cash) => {
            commit('SET_CASH', cash)
        },
        SET_PAYMENT_CHANNEL: ({ commit }, paymentChannel) => {
            commit('SET_PAYMENT_CHANNEL', paymentChannel)
        },
        SET_IS_SELECTED: ({ commit }, isSelected) => {
            commit('SET_IS_SELECTED', isSelected)
        },
        SET_SHOW_MODAL: ({ commit }, modal) => {
            commit('SET_SHOW_MODAL', modal)
        }
    },
    getters: {
        GET_CASH(state) {
            return state.cash
        },
        GET_PAYMENT_CHANNEL(state) {
            return state.paymentChannel
        },
        GET_IS_SELECTED(state) {
            return state.isSelected
        },
        GET_SHOW_MODAL(state) {
            return state.modal
        }
    }
}
