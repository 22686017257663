import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { homepageStore } from './homepageStore'
import { userInfoStore } from './userInfoStore'
import { paymentStore } from './paymentStore'
import { loadingStore } from './loadingStore'
import { modeStore } from './modeStore'
import { domainconfig } from './domainconfig'
import { notificationconfig } from './notificationconfig'

export const store = new Vuex.Store({
// export default new Vuex.Store({
    modules: {
        homepageStore,
        userInfoStore,
        paymentStore,
        loadingStore,
        modeStore,
        domainconfig,
        notificationconfig
    }
})