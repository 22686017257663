import axios from "axios";
export const homepageStore = {
    state: {
        isWriterMode: false,
        keyword: "",
        history_path: "",
        banners: [],
        lestestPromotionNovel: undefined,
        lestestTranslateNovel: [{
                novel_cover: "",
                novel_title: "",
                last_ep: ""
            }],
        lestestCreateNovel: [{
                novel_cover: "",
                novel_title: "",
                last_ep: ""
            }],
        allNovel: [{
                novel_cover: "",
                novel_title: "",
                last_ep: ""
            }],
        topTenTranslate: [],
        topTenCreate: []

    },
    actions: {
        SET_IS_WRITER_MODE({ commit }, isWriterMode) {
            commit("SET_IS_WRITER_MODE", isWriterMode)
        },
        SET_HISTORY_PATH({ commit }, history_path) {
            commit("SET_HISTORY_PATH", history_path)
        },
        async SET_BANNER({ commit }) {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/module/home&submodule=banner')
            commit("SET_BANNER", data.banner)
        },
        async SET_UPDATE_NOVEL({ commit }) {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/module/home&submodule=update')
            //console.log('latest : ',data)
            commit("SET_UPDATE_NOVEL", data.update_chapter)
        },
        async SET_ALL_NOVEL({ commit }) {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/module/home&submodule=novel')
            commit("SET_ALL_NOVEL", data)
        },
        async SET_TOPTEN_NOVEL({ commit }) {
            const {
                data
            } = await axios.get('https://api2.novelrealm.com/module/home&submodule=top_novel')
            //console.log(data)
            commit("SET_TOPTEN_NOVEL", data)
            commit('SET_LOADING_STATUS', false)
        },
        SET_KEYWORD({ commit }, word) {
            commit("SET_KEYWORD", word)
        },
        async SET_SEARCH_NOVEL({commit}, type){
            //console.log('SET_SEARCH_NOVEL : ',type)
            const formData = new FormData()
            if(type == 'translateNovel'){
                formData.append("translate", "1");
            }else if(type == 'createNovel'){
                formData.append("translate", "0");
            }else if(type == 'promotionNovel'){
                formData.append("translate", "2");
            }
            const {
                data
            } = await axios.post('https://api2.novelrealm.com/?module=home&submodule=allupdate', formData)
            //console.log('novel : ',data)
            if(type == 'translateNovel'){
                commit("SET_ALL_NOVEL", data.update_chapter.translate)
            }else if(type == 'createNovel'){
                commit("SET_ALL_NOVEL", data.update_chapter.original)
            }else if(type == 'promotionNovel'){
                commit("SET_ALL_NOVEL", data.update_chapter.promotion)
            }
            commit('SET_LOADING_STATUS', false)
        },
        async SET_SEARCH_NOVEL_WORD({commit}, word){
            const formData = new FormData()
            formData.append("keyword", word);
            const {
                data
            } = await axios.post('https://api2.novelrealm.com/module/allnovel', formData)
            //console.log('novel : ',data)
            if(data.novel.status == '404'){
                commit("SET_ALL_NOVEL", [], word)
            }
            else{
                commit("SET_ALL_NOVEL", data.novel, word)
            }
            commit('SET_LOADING_STATUS', false)

        },
    },
    mutations: {
        SET_IS_WRITER_MODE(state, isWriterMode) {
            state.isWriterMode = isWriterMode
        },
        SET_HISTORY_PATH(state, history_path) {
            state.history_path = history_path
        },
        SET_BANNER(state, banners) {
            state.banners = banners
        },
        SET_UPDATE_NOVEL(state, lestestNovel) {
            state.lestestPromotionNovel = lestestNovel.promotion
            state.lestestTranslateNovel = lestestNovel.translate
            state.lestestCreateNovel = lestestNovel.original
        },
        SET_ALL_NOVEL(state, allNovel) {
            state.allNovel = allNovel
        },
        SET_TOPTEN_NOVEL(state, topten) {
            state.topTenTranslate = topten.translate
            state.topTenCreate = topten.original
        },
        SET_KEYWORD(state, word) {
            state.keyword = word
        }
    },
    getters: {
        GET_IS_WRITER_MODE(state) {
            return state.isWriterMode
        },
        GET_HISTORY_PATH(state) {
            return state.history_path
        },
        GET_BANNER(state) {
            return state.banners
        },
        GET_LESTEST_PROMOTION_NOVEL(state) {
            return state.lestestPromotionNovel
        },
        GET_LESTEST_TRANSLATE_NOVEL(state) {
            return state.lestestTranslateNovel
        },
        GET_LESTEST_CREATE_NOVEL(state) {
            return state.lestestCreateNovel
        },
        GET_ALL_NOVEL(state) {
            return state.allNovel
        },
        GET_TOPTEN_TRANSLATE_NOVEL(state) {
            return state.topTenTranslate
        },
        GET_TOPTEN_CREATE_NOVEL(state) {
            return state.topTenCreate
        },
        GET_KEYWORD(state){
            return state.keyword
        }
    }
}