<template>
<div class="mt-4">
    <div v-if="manageNovel">
        <div>
            <div>
                <span>นิยายทั้งหมด X เรื่อง</span>
                <span class="addNovelBtn" v-b-toggle.createNovel>+ เพิ่มนิยาย</span>
            </div>
            <b-sidebar id="createNovel" title="เขียนนิยายใหม่" width="42em" right backdrop shadow>
                <template v-slot:footer="{ hide }">
                    <div class="d-flex text-light align-items-center px-4 py-3">
                        <strong class="mr-auto">Footer</strong>
                        <b-button size="sm" class="cancel mr-3" @click="hide">ยกเลิก</b-button>
                        <b-button size="sm" @click="hide" class="save">บันทึก</b-button>
                    </div>
                </template>
                <CreateNovel />
            </b-sidebar>
        </div>
        <div class="mt-5">
            <b-row class="pl-3 pr-3">
                <b-col cols="1">#</b-col>
                <b-col>ชื่อนิยาย</b-col>
                <b-col cols="2" v-if="$vssWidth>=540">จำนวนตอน</b-col>
                <b-col cols="3" sm="2" class="text-right">จัดการ</b-col>
            </b-row>
            <b-row v-for="(novel,index) in novels" :key="index" class="p-3 mt-2 mb-2 novelList">
                <b-col @click="getMyChapter" cols="1" class="novel">{{novel.novel_id}}</b-col>
                <b-col @click="getMyChapter" class="novel">{{novel.novel_name}}
                    <div v-if="$vssWidth<=540">{{novel.count_chapter}} ตอน</div>
                </b-col>
                <b-col @click="getMyChapter" cols="2" class="novel" v-if="$vssWidth>=540">{{novel.count_chapter}}</b-col>
                <b-col cols="3" sm="2" class="text-right">
                    <span class="editBtn mr-2" @click="editNovel" v-b-toggle.createNovel>
                        <b-icon-pencil class="mr-1"></b-icon-pencil><span v-if="$vssWidth>=540">แก้ไข</span>
                    </span>
                    <span class="delBtn">
                        <b-icon-trash class="mr-1"></b-icon-trash><span v-if="$vssWidth>=540">ลบ</span>
                    </span>
                </b-col>
            </b-row>
        </div>
    </div>
    <div v-else>
        <div>
            <div>
                <a-icon type="left" class="backToNovel mr-2" @click="manageNovel=true" />
                <span>ชื่อนิยาย ทั้งหมด 1 ตอน</span>
                <span class="addNovelBtn" v-b-toggle.createChapter>+ เพิ่มตอน</span>
            </div>
            <b-sidebar id="createChapter" title="เขียนตอนใหม่" width="42em" right backdrop shadow>
                <template v-slot:footer="{ hide }">
                    <div class="d-flex text-light align-items-center px-4 py-3">
                        <strong class="mr-auto">Footer</strong>
                        <b-button size="sm" class="cancel mr-3" @click="hide">ยกเลิก</b-button>
                        <b-button size="sm" @click="hide" class="save">บันทึก</b-button>
                    </div>
                </template>
                <CreateChapter />
            </b-sidebar>
        </div>
        <div class="mt-5">
            <b-row class="pl-3 pr-3">
                <b-col cols="1">#</b-col>
                <b-col cols="1" v-if="$vssWidth>=540">ตอนที่</b-col>
                <b-col>ชื่อตอน</b-col>
                <b-col cols="2" v-if="$vssWidth>=540">ยอดซื้อ</b-col>
                <b-col cols="2" v-if="$vssWidth>=540">รายได้</b-col>
                <b-col cols="3" sm="2" class="text-right">จัดการ</b-col>
            </b-row>
            <b-row v-for="(chapter,index) in chapters" :key="index" class="p-3 mt-2 mb-2 novelList">
                <b-col cols="1">{{chapter.chapter_id}}</b-col>
                <b-col cols="1" v-if="$vssWidth>=540">{{chapter.chapter_ep}}</b-col>
                <b-col>
                    <div v-if="$vssWidth<=540">ตอนที่:  {{chapter.chapter_ep}} </div>
                    {{chapter.chapter_name}}
                    <div v-if="$vssWidth<=540">ยอดซื้อ:  {{chapter.count_buy}} </div>
                    <div v-if="$vssWidth<=540">รายได้: {{chapter.income}} บาท</div>
                </b-col>
                <b-col cols="2" v-if="$vssWidth>=540">{{chapter.count_buy}}</b-col>
                <b-col cols="2" v-if="$vssWidth>=540">{{chapter.income}}</b-col>
                <b-col cols="3" sm="2" class="text-right">
                    <span class="editBtn mr-2" v-b-toggle.createChapter>
                        <b-icon-pencil class="mr-1"></b-icon-pencil><span v-if="$vssWidth>=540">แก้ไข</span>
                    </span>
                    <span class="delBtn">
                        <b-icon-trash class="mr-1"></b-icon-trash><span v-if="$vssWidth>=540">ลบ</span>
                    </span>
                </b-col>
            </b-row>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import CreateNovel from "./CreateNovel"
import CreateChapter from "./CreateChapter"
export default {
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            manageNovel: true,
            novels: [{
                    novel_id: 1,
                    novel_name: 'ท้าทายดินแดนพระเจ้า',
                    count_chapter: 10
                },
                {
                    novel_id: 2,
                    novel_name: 'ท้าทายดินแดนพระเจ้า',
                    count_chapter: 10
                }
            ],
            chapters: [{
                chapter_ep: 22,
                chapter_id: 1,
                chapter_name: 'ท้าทายดินแดนพระเจ้า',
                count_buy: 10,
                income: 1000
            }]
        }
    },
    components: {
        CreateNovel,
        CreateChapter
    },
    mounted() {
        this.getMyNovel()
    },
    methods: {
        async getMyNovel() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            formData.append('page', 1);
            try {
                await axios.post('https://api2.novelrealm.com/module/writer&submodule=novel_list', formData)
                    .then((res) => {
                        console.log(res)
                        // if (res.data.status == 200) {
                        //     router.push("/novel/" + novel.novel_id + "/chapter/" + novel.chapter_id);
                        // } else if (res.data.status == 404) {
                        // }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        editNovel() {
            //console.log('edit Novel')
        },
        getMyChapter() {
            this.manageNovel = false
            //console.log('get chapter')
        }
    }
};
</script>

<style scoped>
.addNovelBtn {
    float: right;
    background-color: rgba(106, 181, 232, 0.85);
    padding: 5px 14px;
    border-radius: 7px;
    color: #ffffff;
}

.addNovelBtn:hover {
    background-color: rgba(106, 181, 232, 0.95);
}

.addNovelBtn:focus,
.editBtn:focus,
.delBtn:focus {
    outline: 0;
}

.addNovelBtn:hover {
    cursor: pointer;
}

.novelList {
    background-color: #ffffff;
    border: 1px solid #EEEEF0;
    box-shadow: 0px 0px 10px rgba(92, 92, 92, 0.1);
    border-radius: 7px;
}

.novel {
    transform: translateZ(0px);
    transition-duration: .5s;
}

.novel:hover {
    cursor: pointer;
    transform: scale(1.007);
}

.editBtn,
.delBtn {
    padding: 5px 14px;
    border-radius: 5px;
}

.editBtn {
    color: #7A65B5;
    background: rgba(133, 100, 174, 0.2);
}

.editBtn:hover {
    cursor: pointer;
    background: rgba(133, 100, 174, 0.25);
}

.delBtn {
    color: #EDBC62;
    background: rgba(237, 188, 98, 0.2);
}

.delBtn:hover {
    cursor: pointer;
    background: rgba(237, 188, 98, 0.27);
}

.save,
.cancel {
    border: none;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
}

.save {
    background-color: #CB5F85;
}

.backToNovel {
    color: #CB5F85;
    font-size: 24px;
    transform: translateZ(0px);
    transition-duration: .5s;
}

.backToNovel:hover {
    cursor: pointer;
    transform: scale(1.2);
}

@media screen and (max-width: 500px) {

    .editBtn,
    .delBtn {
        padding: 0px;
        background: none;
    }

    .editBtn:hover,
    .delBtn:hover {
        background: none;
    }

}
</style>
