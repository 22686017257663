<template>
<div>
    Tax
</div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
    }
};
</script>

<style scoped>

</style>
