import Vue from 'vue'
import App from './App.vue'
import router from './router'


import Axios from 'axios';
Vue.use(Axios)


import Vuex from 'vuex'
Vue.use(Vuex)


import { store } from './components/store/store'
import VueMatomo from 'vue-matomo'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://traffic.novelrealm.com',
  siteId: 1,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: true,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: false,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: 'novelrealm.com',

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: [],

  // A function to determine whether to track an interaction as a site search
  // instead of as a page view. If not a function, all interactions will be
  // tracked as page views. Receives the new route as an argument, and
  // returns either an object of keyword, category (optional) and resultsCount
  // (optional) to track as a site search, or a falsey value to track as a page
  // view.
  // Default: false, i.e. track all interactions as page views
  // Example: (to) => {
  //   if (to.query.q && to.name === 'search') {
  //     return { keyword: to.query.q, category: to.params.category }
  //   } else {
  //    return null
  //   }
  // }
  trackSiteSearch: false
});


// import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


import 'vue-datetime/dist/vue-datetime.css'
import { Datetime } from 'vue-datetime';
Vue.component('datetime', Datetime);


import Toasted from 'vue-toasted';
Vue.use(Toasted)


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)


import BackToTop from 'vue-backtotop'
Vue.use(BackToTop)


import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));


import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);
Vue.loadScript("https://cdn.omise.co/omise.js")
  .then(() => {
    //console.log('load script', window.OmiseCard)
    // window.OmiseCard.configure({
    //   publicKey: 'pkey_5nlkgnrd1smr1i298s7'
    // });
  })
  .catch(() => {
    //console.log('fail script')
  });

import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
for (let rule in rules) {
  extend(rule, rules[rule]);
}
extend('required', {
  validate: value => !!value,
  message: 'กรุณากรอกข้อมูล'
});
extend('email', {
  message: 'อีเมลไม่ถูกต้อง'
});
extend('alpha', {
  message: 'ต้องมีตัวอักษรอย่างเดียว'
});
extend('alpha_num', {
  message: 'ต้องมีตัวอักษรหรือตัวเลข'
});
extend('numeric', {
  message: 'ต้องมีตัวเลข'
});
extend('confirmed', {
  message: 'รหัสผ่านไม่ตรงกัน'
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('Loading', Loading)

import VueThailandAddress from 'vue-thailand-address'; 
import 'vue-thailand-address/dist/vue-thailand-address.css';
Vue.use(VueThailandAddress);

Vue.use(require('vue-moment'));
Vue.config.productionTip = false

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app')