<template>
<div class="scope">
    <b-col>
        <label for="chapterName">ชื่อตอน</label>
        <b-form-input id="chapterName" v-model="chapterName" placeholder="ชื่อตอน"></b-form-input>
    </b-col>
    <b-row>
        <b-col>
            <label for="name">วันที่และเวลาที่โอน</label>
            <datetime type="datetime" v-model="datetime" :input-style="inputStyle" input-class="form-control" placeholder="วันที่และเวลาที่โอน (ตามสลิปหลักฐาน)"></datetime>
        </b-col>
        <b-col>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col>
            <label for="desc">คำอธิบาย</label>
            <ckeditor id="desc" :editor="editor" v-model="editorData"></ckeditor>
        </b-col>
    </b-row>
</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            chapterName: "",
            datetime: "",
            editor: ClassicEditor,
            editorData: ""
        }
    },
    props: {
        inputStyle: {
            type: [Object, Array, String],
            default: "border-radius: 8px;width: 100%;border: 1px solid #A8A8A8;height: 35px;fontSize:14px;background:transparent"
        }
    }
}
</script>

<style scoped>
@import 'custom.css';

.scope {
    padding: 2%;
    height: 100%;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
}

label {
    font-size: 12px;
    margin-bottom: 0px;
}

input,
select {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 8px;
    background-color: transparent;
    margin-bottom: 3%;
}

input:focus,
select:focus {
    border-color: #A8A8A8;
    box-shadow: none;
}
</style>
