<template>
<div>
    <b-navbar class="navbarBoot" toggleable="lg" type="dark" variant="info" v-bind:sticky='true'>
        <b-navbar-brand class="mr-5 novelrealm" @click="goToHomepage">NOVELREALM</b-navbar-brand>
    </b-navbar>
    <div>
        <b-row>
            <b-col sm="2" class="menu pl-0" v-if="$vssWidth>=540">
                <Menu @menuSelected="menuSelected"/>
            </b-col>
            <b-col style="backgroundColor:#FAFBFC">
                <Dashboard v-if="menu=='dashboard'"/>
                <ManageNovel v-else-if="menu=='manageNovel'"/>
                <SaleCheck v-else-if="menu=='saleCheck'"/>
                <History v-else-if="menu=='history'"/>
                <Tax v-else-if="menu=='tax'"/>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import router from '../../router';
import Menu from "./../elements/control/Menu";
import Dashboard from "./../elements/control/Dashboard";
import ManageNovel from "./../elements/control/ManageNovel";
import SaleCheck from "./../elements/control/SaleCheck";
import History from "./../elements/control/History";
import Tax from "./../elements/control/Tax";
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            menu: ""
        }
    },
    mounted() {
        if(location.pathname.substring(location.pathname.lastIndexOf("/")+1) != "Control"){
            this.menu = location.pathname.substring(location.pathname.lastIndexOf("/")+1)
        }else{
            this.menu = "dashboard"
        }
    },
    components: {
        Menu,
        Dashboard,
        ManageNovel,
        SaleCheck,
        History,
        Tax
    },
    methods: {
        ...mapActions(['SET_IS_WRITER_MODE']),
        goToHomepage() {
            this.SET_IS_WRITER_MODE(false)
            router.push("/")
            // location.reload()
        },
        menuSelected(menu){
            this.menu = menu
            router.push("/Control/"+menu)
        }
    }
};
</script>

<style scoped>
.navbarBoot {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(4, 36, 70, 1) 50%, rgba(255, 255, 255, 1) 100%) !important;
}

.novelrealm {
    font-size: 20px;
    font-weight: bold;
}

.novelrealm:hover {
    cursor: pointer;
}

.menu{
    height: 93vh;
    background-color: #ffffff;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}
</style>
