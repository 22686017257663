export const userInfoStore = {
    state: {
        loginStatus: false,
        userInfo: {
            auth_memberid: null,
            auth_token: null,
            displayname: null,
            member_gold: 0,
            member_profile_url: null,
            member_is_admin: 0
        },
        deviceInfo: {},
        analytics: localStorage.getItem('Analytics')
    },
    actions: {
        SET_USER_INFO({ commit }, userInfo) {
            localStorage.setItem('UserInfo', JSON.stringify(userInfo))
            commit("SET_USER_INFO", userInfo)
        },
        SET_DISPLAYNAME({ commit }, displayname){
            commit("SET_DISPLAYNAME", displayname)
        },
        SET_MEMBER_GOLD({ commit }, member_gold){
            commit("SET_MEMBER_GOLD", member_gold)
        },
        SET_MEMBER_PROFILE_URL({ commit }, member_profile_url){
            commit("SET_MEMBER_PROFILE_URL", member_profile_url)
        },
        SET_LOGIN_STATUS({ commit }, loginStatus) {
            localStorage.setItem('LoginStatus', loginStatus);
            commit("SET_LOGIN_STATUS", loginStatus)
        },
        SET_DEVICE_INFO({ commit }, deviceInfo) {
            localStorage.setItem('DeviceInfo', JSON.stringify(deviceInfo))
            commit("SET_USER_INFO", deviceInfo)
        },
        SET_ANALYTICS_INFO({ commit }, val = true) {
            localStorage.setItem('Analytics', val)
            commit("SET_ANALYTICS_INFO", val)
        }
    },
    mutations: {
        SET_USER_INFO(state, userInfo) {
            state.userInfo = userInfo
        },
        SET_DEVICE_INFO(state, deviceInfo) {
            state.deviceInfo = deviceInfo
        },
        SET_DISPLAYNAME(state, displayname){
            state.userInfo.displayname = displayname
            localStorage.setItem('UserInfo', JSON.stringify(state.displayname))
        },
        SET_MEMBER_GOLD(state, member_gold){
            state.userInfo.member_gold = member_gold
            localStorage.setItem('UserInfo', JSON.stringify(state.userInfo))
        },
        SET_MEMBER_PROFILE_URL(state, member_profile_url){
            state.userInfo.member_profile_url = member_profile_url
            localStorage.setItem('UserInfo', JSON.stringify(state.userInfo))
        },
        SET_LOGIN_STATUS(state, loginStatus) {
            state.loginStatus = loginStatus
        },
        SET_ANALYTICS_INFO(state, val) {
            state.analytics = val
        }
    },
    getters: {
        GET_LOGIN_STATUS(state) {
            return state.loginStatus
        },
        GET_USER_INFO(state) {
            return state.userInfo
        },
        GET_DEVICE_INFO(state) {
            return state.deviceInfo
        },
        GET_ANALYTICS_INFO(state) {
            return state.analytics
        }
    }
}